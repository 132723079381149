
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router';
import { clearAuthData } from '../Redux/AuthSlice';

const PrivateRoute = ({ type }) => {
    const token = useSelector((state) => state.auth.token);
    const productType = useSelector((state) => state.auth.type);
    const dispatch = useDispatch();

    // Define isAuthenticated and isCorrectProductType here
    const isAuthenticated = token && token !== "";
    const isCorrectProductType = productType === type;

    useEffect(() => {
        // If not authenticated or incorrect product type, clear auth data
        if (!isAuthenticated || !isCorrectProductType) {
            dispatch(clearAuthData());
        }
    }, [dispatch, token, productType, type,isAuthenticated,isCorrectProductType]);

    // Render Outlet if authenticated and correct product type, else navigate to login
    return (isAuthenticated && isCorrectProductType) ? <Outlet /> : <Navigate to='/' />;
}

export default PrivateRoute;
