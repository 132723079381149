import React, { useState } from 'react';
import api from '../utils/Api';
import { api_url } from '../config';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ColorRing } from 'react-loader-spinner';

const InProgressIssueView = ({ issues, update, fetchBacklogs }) => {

    const handleStatusUpdate = (newStatus) => {
        update(newStatus);
    };

    const [note, setNote] = useState('')
    const { id, token } = useSelector((state) => state.auth)
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(false);




    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setLoading(true);
      
        const data = {
          ...issues,
          notes: note,
        };
      
        try {
          await api.put(`${api_url}/user/${id}/backlogs/${issues.id}`, data, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
      
          await fetchBacklogs();
        } catch (err) {
          setError('Failed to add note. Please try again.');
          toast.error('Failed to add note. Please try again.');
        } finally {
          setLoading(false);
        }
      };

    return (
        <div>
            <div className="flex flex-col gap-2 sm:gap-0 sm:flex-row space-x-4 w-full">
                {/* Subscriber Information */}
                <div className="bg-white p-4 rounded-lg shadow sm:w-1/3 h-fit">
                    <h2 className="text-lg font-semibold mb-4">Subscriber Information</h2>
                    <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
                        <span className="flex flex-col">
                            <p className='text-xs text-gray-500'>Product Name</p>
                            <p> {issues?.app}</p>
                        </span>
                        <span className="flex flex-col">
                            <p className='text-xs text-gray-500'>Priority</p>
                            <p> {issues?.priority}</p>
                        </span>
                        <span className="flex flex-col">
                            <p className='text-xs text-gray-500'>Issued By</p>
                            <p> {issues?.creator?.name}</p>
                        </span>
                    </div>

                </div>

                {/* Log Details */}
                <div className="bg-white p-4 rounded-lg shadow sm:w-2/3">
                    <h2 className="text-lg font-semibold mb-2">Log Details</h2>
                    <div className="flex justify-between text-sm mb-2">

                        <span className="flex flex-col">
                            <p className='text-xs text-gray-500'>Issue ID</p>
                            <p> {issues?.id}</p>
                        </span>
                        <span className="flex flex-col">
                            <p className='text-xs text-gray-500'>Department</p>
                            <p> {issues?.department?.name}</p>
                        </span>
                        <span className="flex flex-col">
                            <p className='text-xs text-gray-500'>Assignee</p>
                            <p> {issues?.assignee?.name}</p>
                        </span>

                    </div>
                    <div className="bg-gray-100 p-2 rounded">
                        <strong className="text-xs text-gray-500">Issue Details</strong>
                        <p className="text-sm mt-1">{issues?.description}</p>
                    </div>
                    <div className="bg-gray-100 p-2 rounded mt-2">
                        <strong className="text-xs text-gray-500">Notes</strong>
                        {/* <p className="text-sm mt-1">{issues?.description}</p> */}
                        {issues?.notes?.map((note)=>(
                            <p key={note.id} className="text-sm mt-1">{note?.note}</p>

                        ))}
                    </div>
                </div>
            </div>
            <div className='flex justify-end mt-3'>
                <textarea
                    rows={3}
                    className='border border-gray-200 rounded-md w-3/4 p-3 focus:outline-none text-sm'
                    placeholder='Add notes...'
                    value={note}
                    onChange={(e) => { setNote(e.target.value) }}
                />

            </div>
            <div className='flex  justify-end mt-2'>
                <button
                    onClick={handleSubmit}
                    className="bg-gray-500 text-xs text-white px-2 py-1 rounded hover:bg-gray-600 transition-colors">
                    {isLoading ?
                        <ColorRing
                            visible={true}
                            height="20"
                            width="20"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{}}
                            wrapperClass="color-ring-wrapper"
                            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                        /> : 'Add notes'}
                </button>
            </div>

            {/* Accept and Process Button */}
            <div className="text-right mt-4">
                <button
                    onClick={() => handleStatusUpdate('resolved')}
                    className="bg-blue-500 text-sm text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors">
                    Mark As Resolved
                </button>
            </div>
        </div>
    );
}

export default InProgressIssueView;
