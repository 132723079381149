import React from "react";
import { Route, Router, Routes } from "react-router-dom";
import AuthPage from "./pages/AuthPage";
import LoginForm from "./components/LoginForm";
import ForgotPasswordForm from "./components/ForgotPasswordForm";
import Layout from "./utils/Layout";
import SubscribersPage from "./pages/SubscribersPage";
import BacklogsPage from "./pages/BacklogsPage";
import TasksPage from "./pages/TasksPage";
import ArchivePage from "./pages/ArchivePage";
import DepartmentsPage from "./pages/DepartmentsPage";
import UsersPage from "./pages/UsersPage";
import InternalLoginForm from "./components/internalAdmin/InternalLoginForm";
import InternalForgotPassword from "./components/internalAdmin/InternalForgotPassword";
import SubscriberDetail from "./components/SubscriberDetail";
import TokenSection from "./components/internalAdmin/TojenSection";
import PrivateRoute from "./utils/PrivateRoute";
import IssueDetailPage from "./pages/IssueDetailPage";
import QualityAssuarancePage from "./pages/QualityAssuarancePage";
import DepartmentDetails from "./components/DepartmentDetails";
import UserDetails from "./components/UserDetails";

function App() {
  return (
    <Routes>
      <Route element={<AuthPage />}>
        <Route path="/" element={<LoginForm />} />
        <Route path="/forgot-password" element={<ForgotPasswordForm />} />
      </Route>
      <Route path='/internal-admin' element={<InternalLoginForm />} />
      <Route path='/internal-admin-forgot-password' element={<InternalForgotPassword />} />
      <Route element={<PrivateRoute type="user" />}>
        <Route path="/dashboard/" element={<Layout />}>
          <Route path="subscribers" element={<SubscribersPage />} />
          <Route path="subscribers/:id" element={<SubscriberDetail />} />
          <Route path="backlogs" element={<BacklogsPage />} />
          <Route path="backlogs/:id" element={<IssueDetailPage />} />
          <Route path="tasks" element={<TasksPage />} />
          <Route path="tasks/:id" element={<IssueDetailPage />} />
          <Route path="qa" element={<QualityAssuarancePage />} />
          <Route path="archive" element={<ArchivePage />} />
          <Route path="departments" element={<DepartmentsPage />} />
          <Route path="departments/:id" element={<DepartmentDetails />} />
          <Route path="users" element={<UsersPage />} />
          <Route path="users/:id" element={<UserDetails/>} />
        </Route>
      </Route>
      <Route element={<PrivateRoute type="admin" />}>
        <Route path="generate-token" element={<TokenSection />} />
      </Route>
    </Routes>
  );
}

export default App;
