import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { api_url } from '../config';
import api from '../utils/Api';
import { useSelector } from 'react-redux';

const AddDepartmentModal = ({ isOpen, onClose,fetchDepartments }) => {
    const [isAnimating, setIsAnimating] = useState(false);
    const [departmentName, setDepartmentName] = useState('');
    const [loading,setLoading] = useState(false)
    const [error, setError] = useState('')
    const {id,token} = useSelector((state)=>state.auth)
    



    useEffect(() => {
        if (isOpen) {
            setIsAnimating(true);
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [isOpen]);

    if (!isOpen && !isAnimating) return null;

    const handleAnimationEnd = () => {
        if (!isOpen) setIsAnimating(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();


    };

    const addDepartment = async () => {
        if (departmentName.trim() === '') {
            setError('Department name cannot be empty');
            return;
        }
        setLoading(true)
        try {
           await api.post(`${api_url}/user/${id}/department`,
                { name: departmentName },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                }
            );

            toast.success('Department added successfully');
            fetchDepartments();
            setDepartmentName('');
            setError('');
            onClose();
        } catch (error) {
            console.error('Error adding department:', error);
            toast.error('Error adding department');
        }finally{
            setLoading(false)
        }
    };

    return (
        <div
            className={`fixed inset-0 bg-black bg-opacity-50 flex p-8 justify-center items-center z-50 transition-opacity duration-300 ease-in-out ${isOpen ? 'opacity-100' : 'opacity-0'}`}
            onClick={onClose}>
            <div
                className={`bg-white rounded-lg w-full max-w-md max-h-full transform transition-all duration-300 ease-in-out overflow-auto ${isOpen ? 'scale-100 opacity-100' : 'scale-95 opacity-0'}`}
                onClick={e => e.stopPropagation()}
                onTransitionEnd={handleAnimationEnd}
            >
                <div className="flex justify-between items-center p-4 border-b">
                    <h2 className="text-base font-semibold">Add Department</h2>
                    <button
                        onClick={onClose}
                        className="text-gray-500 hover:text-gray-700"
                    >
                        ×
                    </button>

                </div>
                <form onSubmit={handleSubmit} className='p-3' >
                    <div className="mb-4">
                        <label htmlFor="departmentName" className="block text-sm font-medium text-gray-700 mb-2">
                            Department Name
                        </label>
                        <input
                            type="text"
                            id="departmentName"
                            value={departmentName}
                            onChange={(e) => setDepartmentName(e.target.value)}
                            placeholder="Enter Department Name"
                            className="w-full px-3 py-1 text-sm border border-gray-300 rounded-md shadow-sm focus:outline-none "
                            required
                        />
                    </div>
                    <div className="flex justify-end space-x-2">
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-4 py-2 text-sm bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500"
                        >
                            Cancel
                        </button>
                        <button
                        onClick={addDepartment}
                        disabled={loading}
                            className="px-4 py-2 text-sm bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                            {!loading ? `Add Department`:`loading`}
                        </button>
                    </div>
                </form>

            </div>
        </div>
    );
}

export default AddDepartmentModal;
