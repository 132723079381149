import React from 'react';
import { Switch, Link, Outlet } from 'react-router-dom';
import LoginForm from '../components/LoginForm';
import ForgotPasswordForm from '../components/ForgotPasswordForm';

const AuthPage = () => {
    return (
        <div className='bg-gradient-to-b from-blue-600 via-green-600 to-white h-screen flex justify-center items-center'>
         <Outlet/>
        </div>
    );
}

export default AuthPage;
