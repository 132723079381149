import React from 'react';
import { useSelector } from 'react-redux';

const Stepper = ({ issues }) => {
   
    const steps = [
        { label: "Created", status: "created" },
        { label: "Pending", status: "pending" },
        { label: "In progress", status: "in_progress" },
        { label: "Resolved", status: "resolved" },
        { label: "Confirmed", status: "confirmed" },
        
    ];

    const getStepStatus = (stepStatus) => {
        const issueIndex = steps.findIndex(step => step.status === issues?.status);
        const stepIndex = steps.findIndex(step => step.status === stepStatus);

        if (stepIndex < issueIndex) return "completed";
        if (stepIndex === issueIndex) return "completed";
        if (stepIndex === issueIndex + 1) return "current";
        return "inactive";
    };


    return (
        <div className="bg-white shadow-md rounded-lg p-3 w-full overflow-auto font-lufga">
                <div className="flex flex-col items-center text-sm text-gray-600">
                    <h2 className="text-sm font-semibold">Issue Status</h2>

                    <p>Issue id: <span className='font-semibold'>{issues?.id}</span></p>
                    <p>Date: <span className='font-semibold'>{new Date(issues?.created_at).toLocaleDateString()}</span></p>
                </div>
           
            <div className="relative flex items-center justify-between">
                {steps.map((step, index) => (
                    <div key={step.status} className="flex flex-col items-center">
                        <div className={`w-6 h-6 rounded-full border-2 flex items-center justify-center z-10
                             ${getStepStatus(step.status) === "completed" ? "border-green-500 bg-green-500" :
                                getStepStatus(step.status) === "current" ? "border-green-500" : "border-gray-300"}`}>
                            {getStepStatus(step.status) === "completed" && (
                                <svg className="w-4 h-4 text-white" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                </svg>
                            )}
                        </div>
                        {index < steps.length - 1 && (
                            <div className={`absolute top-3 left-0 h-0.5 -z-10
                ${index < steps.findIndex(s => s.status === issues?.status) ? "bg-green-500" : "bg-gray-300"}`}
                                style={{ width: `${100 / (steps.length - 1)}%`, left: `${(100 / (steps.length - 1)) * index}%` }}></div>
                        )}
                        <span className="mt-2 hidden sm:flex text-[10px] text-center">{step.label}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Stepper;