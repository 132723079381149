import React, { useEffect, useState } from "react";
import { ImFileEmpty } from "react-icons/im";
import { DNA } from "react-loader-spinner";
import { useSelector } from "react-redux";
import api from "../utils/Api";
import { api_url } from "../config";
import { toast } from "react-toastify";
import IssueDetailsModal from '../components/IssueDetailsModal';

const ArchivePage = () => {
    const [loading, setLoading] = useState(true);
    const [issues, setIssues] = useState([]);
    const { token, id } = useSelector((state) => state.auth);
    const [selectedIssue, setSelectedIssue] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [filters, setFilters] = useState({
        app: '',
        status: '',
        search: '',
        priority: ''
    });
    const [pagination, setPagination] = useState({
        currentPage: 1,
        lastPage: 1,
        total: 0,
        perPage: 15
    });

    // Get unique values for filter dropdowns
    // const uniqueProducts = [...new Set(issues.map(item => item.app))];
    const uniqueProducts = ['pharmtrix-wholesale','pharmtrix-retail','billtrix','centrix','labtrix'];
    const statuses = ['confirmed', 'in_progress', 'pending', 'resolved'];
    const priorities = ['high', 'medium', 'low'];


    const fetchIssues = async () => {
        try {
            const params = {
                page: pagination.currentPage,
                limit: pagination.perPage,
            };

            if (filters.app) params.app = filters.app;
            if (filters.priority) params.priority = filters.priority;
            if (filters.search) params.search = filters.search;

            const response = await api.get(`${api_url}/user/${id}/backlogs?status=confirmed`, {
                params,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });

            setIssues(response.data.data || []);
            setPagination(prev => ({
                ...prev,
                currentPage: response.data.meta.current_page,
                lastPage: response.data.meta.last_page,
                total: response.data.meta.total,
                perPage: response.data.meta.per_page
            }));
        } catch (error) {
            console.error('Error fetching QA Issues:', error);
            toast.error('Error in fetching QA Issues');
        } finally {
            setLoading(false);
        }
    };

    const debounce = (func, delay) => {
        let timeoutId;
        return (...args) => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => func(...args), delay);
        };
    };

    const handleFilterChange = (filterType, value) => {
        setFilters(prev => ({
            ...prev,
            [filterType]: value
        }));
        setPagination(prev => ({
            ...prev,
            currentPage: 1
        }));
    };

    const debouncedSearch = debounce((value) => {
        handleFilterChange('search', value.trim());
    }, 500);

    const handleSearchChange = (e) => {
        const { value } = e.target;
        debouncedSearch(value);
    };

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= pagination.lastPage) {
            setPagination(prev => ({
                ...prev,
                currentPage: newPage
            }));
        }
    };

    const getPageNumbers = () => {
        const currentPage = pagination.currentPage;
        const totalPages = pagination.lastPage;
        const maxVisiblePages = 5;

        let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
        let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

        if (endPage - startPage + 1 < maxVisiblePages) {
            startPage = Math.max(1, endPage - maxVisiblePages + 1);
        }

        return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    };

    const getItemRange = () => {
        const start = ((pagination.currentPage - 1) * pagination.perPage) + 1;
        const end = Math.min(pagination.currentPage * pagination.perPage, pagination.total);
        return { start, end };
    };

    const handleRowClick = (issue) => {
        setSelectedIssue(issue);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedIssue(null);
    };


    const getPriorityRowClass = (priority) => {
        switch (priority.toLowerCase()) {
            case 'high':
                return 'bg-red-50 text-red-600';
            case 'medium':
                return 'bg-yellow-50 text-yellow-600';
            case 'low':
                return 'bg-green-50 text-green-700';
            default:
                return '';
        }
    };

    useEffect(() => {
        fetchIssues();
    }, [pagination.currentPage, filters]);

    return (
        <div className="h-full relative">
            {loading ? (
                <div className="flex justify-center items-center w-full h-full backdrop-blur-sm absolute top-0 left-0">
                    <DNA
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="dna-loading"
                        wrapperStyle={{}}
                        wrapperClass="dna-wrapper"
                    />
                </div>
            ) : (
                <div>
                    <div className="flex justify-between items-center m-5">
                        <p className="font-semibold text-base">Quality Assurance</p>
                    </div>

                    {/* Search and Filters Section */}
                    <div className="flex gap-4 mb-4 px-4 items-center">
                        <input
                            type="text"
                            placeholder="Search issues..."
                            className="border border-gray-300 rounded-md p-2 text-xs w-64"
                            onChange={handleSearchChange}
                        />

                        <select
                            className="border border-gray-300 rounded-md p-2 text-xs"
                            value={filters.app}
                            onChange={(e) => handleFilterChange('app', e.target.value)}
                        >
                            <option value="">All Products</option>
                            {uniqueProducts.map(product => (
                                <option key={product} value={product}>{product}</option>
                            ))}
                        </select>
                        <select
                            className="border border-gray-300 rounded-md p-2 text-xs"
                            value={filters.priority}
                            onChange={(e) => handleFilterChange('priority', e.target.value)}
                        >
                            <option value="">All Priorities</option>
                            {priorities.map(priority => (
                                <option key={priority} value={priority}>{priority}</option>
                            ))}
                        </select>
                    </div>

                    {issues.length > 0 ? (
                        <div className="max-w-[2000px]">
                            <div className="overflow-x-auto shadow-lg rounded-lg">
                                <table className="min-w-full table-auto text-xs bg-white">
                                    <thead>
                                        <tr className="bg-gray-100 text-left">
                                            <th className="px-4 py-2">Date</th>
                                            <th className="px-4 py-2">Issue ID</th>
                                            <th className="px-4 py-2">Product</th>
                                            <th className="px-4 py-2">Subscriber</th>
                                            <th className="px-4 py-2">Assignee</th>
                                            <th className="px-4 py-2">Department</th>
                                            <th className="px-4 py-2 text-center">Status</th>
                                            <th className="px-4 py-2">Priority</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {issues.map((item) => (
                                            <tr
                                                key={item.id}
                                                className={`border-b hover:bg-gray-50 cursor-pointer transition duration-150 ease-in-out `}
                                                onClick={() => handleRowClick(item)}
                                            >
                                                <td className="px-4 py-2">{new Date(item?.created_at).toLocaleDateString()}</td>
                                                <td className="px-4 py-2">{item?.id}</td>
                                                <td className="px-4 py-2">{item?.app}</td>
                                                <td className="px-4 py-2">{item?.subscriber}</td>
                                                <td className="px-4 py-2">{item?.assignee?.name}</td>
                                                <td className="px-4 py-2">{item?.department?.name}</td>
                                                <td className="px-4 py-2 ">
                                                    <p className="text-green-500 bg-green-50 px-2 py-1 text-center rounded-lg border border-green-200">{item.status.charAt(0).toUpperCase() + item?.status.slice(1)}</p>
                                                </td>
                                                <td className={`px-4 py-2 ${getPriorityRowClass(item.priority)}`}>{item?.priority}</td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            {/* Pagination Section */}
                            <div className="flex items-center justify-between px-4 py-3 my-8 border-t border-gray-200 sm:px-6">
                                <div className="flex items-center text-xs text-gray-700">
                                    <span>
                                        Showing <span className="font-medium">{getItemRange().start}</span>{' '}
                                        to <span className="font-medium">{getItemRange().end}</span>{' '}
                                        of <span className="font-medium">{pagination.total}</span> results
                                    </span>
                                </div>
                                <div className="flex items-center justify-end gap-2">
                                    <button
                                        onClick={() => handlePageChange(pagination.currentPage - 1)}
                                        disabled={pagination.currentPage === 1}
                                        className={`px-3 py-1 text-xs rounded-md ${pagination.currentPage === 1
                                                ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                                                : 'bg-white text-gray-700 hover:bg-gray-50 border'
                                            }`}
                                    >
                                        Previous
                                    </button>

                                    {getPageNumbers().map((pageNum) => (
                                        <button
                                            key={pageNum}
                                            onClick={() => handlePageChange(pageNum)}
                                            className={`px-3 py-1 text-xs rounded-md ${pageNum === pagination.currentPage
                                                    ? 'bg-blue-500 text-white'
                                                    : 'bg-white text-gray-700 hover:bg-gray-50 border'
                                                }`}
                                        >
                                            {pageNum}
                                        </button>
                                    ))}

                                    <button
                                        onClick={() => handlePageChange(pagination.currentPage + 1)}
                                        disabled={pagination.currentPage === pagination.lastPage}
                                        className={`px-3 py-1 text-xs rounded-md ${pagination.currentPage === pagination.lastPage
                                                ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                                                : 'bg-white text-gray-700 hover:bg-gray-50 border'
                                            }`}
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="w-full h-full flex justify-center items-center">
                            <span className="flex flex-col items-center justify-center gap-3">
                                <ImFileEmpty size={48} className="text-blue-600" />
                                <p className="text-xs">No issues found.</p>
                            </span>
                        </div>
                    )}

                    {/* Modal Component */}
                    <IssueDetailsModal
                        isOpen={isModalOpen}
                        onClose={closeModal}
                        issue={selectedIssue}
                    />


                </div>
            )}

        </div>
    );
};

export default ArchivePage;