import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { api_url } from '../config';
import api from '../utils/Api';
import { useSelector } from 'react-redux';
import { ColorRing } from 'react-loader-spinner';

const ResetPasswordModal = ({ isOpen, onClose, user }) => {
    const [isAnimating, setIsAnimating] = useState(false);
    const [departmentName, setDepartmentName] = useState('');
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const { id, token } = useSelector((state) => state.auth)


    useEffect(() => {
        if (isOpen) {
            setIsAnimating(true);
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [isOpen]);

    if (!isOpen && !isAnimating) return null;

    const handleAnimationEnd = () => {
        if (!isOpen) setIsAnimating(false);
    };


    const resetPassword = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await api.put(`${api_url}/user/${id}/user-profile/${user.id}/reset-password`,
                {},
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`
                    }
                }
            );
            toast.success('Reset password successful')
            onClose()

        } catch (error) {
            // If there's an error, extract a user-friendly error message
            setError("An error occurred while resetting the password.");
            toast.error("An error occured")
        } finally {
            setLoading(false);
        }
    };

    return (
        <div
            className={`fixed inset-0 bg-black bg-opacity-50 flex p-8 justify-center items-center z-50 transition-opacity duration-300 ease-in-out ${isOpen ? 'opacity-100' : 'opacity-0'}`}
            onClick={onClose}>
            <div
                className={`bg-white rounded-lg w-full max-w-md max-h-full transform transition-all duration-300 ease-in-out overflow-auto ${isOpen ? 'scale-100 opacity-100' : 'scale-95 opacity-0'}`}
                onClick={e => e.stopPropagation()}
                onTransitionEnd={handleAnimationEnd}
            >
                <div className="flex justify-between items-center p-4 border-b">
                    <h2 className="text-base font-semibold">Reset User Password</h2>
                    <button
                        onClick={onClose}
                        className="text-gray-500 hover:text-gray-700"
                    >
                        ×
                    </button>

                </div>
                <div className='p-4'>
                    <p>
                        Are you sure you want to reset this user's password?
                    </p>
                    <div className="flex justify-end space-x-2 mt-3">
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-4 py-2 text-sm bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={resetPassword}
                            disabled={loading}
                            className="px-4 py-2 text-sm bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                            {!loading ? `Confirm` : <ColorRing
                                visible={true}
                                height="20"
                                width="20"
                                ariaLabel="color-ring-loading"
                                wrapperStyle={{}}
                                wrapperClass="color-ring-wrapper"
                                colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                            />}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResetPasswordModal;
