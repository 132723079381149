import { configureStore } from '@reduxjs/toolkit';
import authReducer from './Redux/AuthSlice';
import userReducer from './Redux/UsersSlice';
import departmentReducer from './Redux/DepartmentSlice'

const store = configureStore({
    reducer:{
        auth:authReducer,
        users:userReducer,
        departments: departmentReducer
    }
})

export default store;