import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import UpdateBacklogForm from './UpdateBacklogForm';
import { useSelector } from 'react-redux';
import { api_url } from '../config';
import api from '../utils/Api';

const PendingIssueView = ({ issues, fetchBacklogs,update }) => {

    const location = useLocation()
    const isValid = location.pathname.includes('backlogs')
    const [isFormModalOpen, setIsFormModalOpen] = useState(false);


    const handleStatusUpdate = (newStatus) => {
        update(newStatus);
      };

      
    

    return (
        <div>
            <div className="flex flex-col gap-2 sm:gap-0 sm:flex-row space-x-4 w-full">
                {/* Subscriber Information */}
                <div className="bg-white p-4 rounded-lg shadow sm:w-1/3">
                    <h2 className="text-lg font-semibold mb-4">Subscriber Information</h2>
                    <div className='flex flex-col gap-5'>
                        <span className="flex flex-col">
                            <p className='text-xs text-gray-500'>Product Name</p>
                            <p> {issues?.app}</p>
                        </span>
                        <span className="flex flex-col">
                            <p className='text-xs text-gray-500'>Priority</p>
                            <p> {issues?.priority}</p>
                        </span>
                    </div>

                </div>

                {/* Log Details */}
                <div className="bg-white p-4 rounded-lg shadow sm:w-2/3">
                    <div className='flex justify-between mb-2'>
                        <p className="text-base font-semibold ">Log Details</p>
                        {isValid && <button
                            onClick={() => { setIsFormModalOpen(true) }}
                            className="bg-blue-500 text-white px-2 text-xs rounded hover:bg-blue-600 transition-colors">
                            Update
                        </button>}
                    </div>
                    <div className="flex justify-between text-sm mb-2">

                        <span className="flex flex-col">
                            <p className='text-xs text-gray-500'>Issue ID</p>
                            <p> {issues?.id}</p>
                        </span>
                        <span className="flex flex-col">
                            <p className='text-xs text-gray-500'>Department</p>
                            <p> {issues?.department?.name}</p>
                        </span>
                        <span className="flex flex-col">
                            <p className='text-xs text-gray-500'>Assignee</p>
                            <p> {issues?.assignee?.name}</p>
                        </span>

                    </div>
                    <div className="bg-gray-100 p-2 rounded">
                        <strong className="text-xs text-gray-500">Issue Details</strong>
                        <p className="text-sm mt-1">{issues?.description}</p>
                    </div>
                </div>
            </div>

            {/* Accept and Process Button */}
            <div className="text-right mt-4">
                <button 
          onClick={() => handleStatusUpdate('in_progress')}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors">
                    Accept and Process
                </button>
            </div>

            <UpdateBacklogForm
                isOpen={isFormModalOpen}
                onClose={() => setIsFormModalOpen(false)}
                issue={issues}
                fetchBacklogs={fetchBacklogs}
            />
        </div>
    );
}

export default PendingIssueView;
