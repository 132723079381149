
import React, { useEffect, useState } from 'react';
import { IoClose, IoCopyOutline } from 'react-icons/io5';
import api from '../../utils/Api';
import { api_url } from '../../config';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { CiWarning } from 'react-icons/ci';

const GenerateTokenModal = ({ onClose, open, children ,fetchTokens}) => {
    const [name, setName] = useState('');
    const [error, setError] = useState('')
    const [errorStatus, setErrorStatus] = useState(false)
    const [generatedToken, setGeneratedToken] = useState('')
    const { id, token } = useSelector((state) => state.auth)
    const [showToken, setShowToken] = useState(false)
    const [loading, setLoading] = useState(false)
    const [copied, setCopied] = useState(false)

    const handleSubmit = async (e) => {
        setError('')
        setErrorStatus(false)

        e.preventDefault();
        if (name == '') {
            return;
        }
        setLoading(true)
        try {
            const response = await api.post(`${api_url}/api/v1/admin/${id}/tokens`, { name }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            })

            setGeneratedToken(response?.data?.token)
            toast.success('Token created Successfully')
            setShowToken(true)
            setName('');
            fetchTokens()
        } catch (error) {
            toast.error('Sorry something went wrong')
            setErrorStatus(true)
            setError(error?.response?.data?.error)
        } finally {
            setLoading(false)
        }
    };

    useEffect(()=>{
        setShowToken(false)
    },[open])
    const handleCopy = () => {
        navigator.clipboard.writeText(generatedToken).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        });
    };

    return (
        <div

            onClick={onClose}
            className={`fixed inset-0 flex justify-center items-center transition-colors ${open ? "visible bg-black/20" : "invisible"}`}>
            <div
                onClick={e => e.stopPropagation()}
                className={`max-w-md sm:w-[40vw] mx-4  sm:mx-auto bg-white rounded-xl shadow p-6 transition-all ${open ? "scale-100 opacity-100" : "scale-125 opacity-0"}`}
            >
                <h2 className="text-lg font-black text-gray-800 mb-6">Generate Token</h2>
                <form className="flex flex-col gap-6">
                    <div>
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                            Token name
                        </label>
                        <input
                            type="text"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none "
                            placeholder=""
                            required
                        />
                    </div>
                    <button
                        onClick={handleSubmit}
                        type="button"
                        className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none transition duration-200"
                    >
                        {loading ? `processing...` : 'Submit'}
                    </button>

                    <span className='text-xs bg-yellow-100 p-2 text-yellow-700 rounded-md'>
                        <span className='flex items-center'><CiWarning size={20} /><p className='font-bold'>Warning</p></span>
                        <p>The generated token cannot be accessible once modal is closed. Kindly ensure it is copied for future use</p>
                    </span>

                   {showToken && <span className='flex gap-2'>
                   
                    <p className='text-xs'>{generatedToken}</p>
                    <IoCopyOutline
                        className='cursor-pointer hover:text-blue-500'
                        onClick={handleCopy}
                    />
                     {copied && (
                        <span className=" text-green-500 text-xs">Copied!</span>
                    )}
                   </span>}
                  {errorStatus && (<p className="bg-red-300 font-lufga rounded-md text-center shadow-md px-2 py-1 text-sm ">
                            {typeof error === 'string' ? error : (
                                Object.keys(error).map((key) => (
                                    Array.isArray(error[key]) ? error[key][0] : error[key]
                                ))
                            )}
                        </p>)}
                  
                </form>
            </div>
        </div>
    );
};

export default GenerateTokenModal;
