import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { searchUsers, clearSearchResults } from '../Redux/UsersSlice';
import debounce from 'lodash/debounce';
import { api_url } from '../config';
import { X } from 'lucide-react';
import api from '../utils/Api';
import { ColorRing } from 'react-loader-spinner';

const UpdateDepartmentModal = ({ isOpen, onClose, department, token, fetchDepartmentDetails }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    name: '',
    selectedUsers: new Set()
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUserDetails, setSelectedUserDetails] = useState([]);
  const [loading, setLoading] = useState(false)

  // Get users from Redux store
  const { searchResults, searchStatus } = useSelector((state) => state.users);
  const { id: userId } = useSelector((state) => state.auth);

  // Initialize form data when department data is available
  useEffect(() => {
    if (department) {
      const userIds = new Set(department.users.map(user => user.id));
      setFormData({
        name: department.name,
        selectedUsers: userIds
      });
      setSelectedUserDetails(department.users);
    }
  }, [department]);

  // Clear search results when modal closes
  useEffect(() => {
    if (!isOpen) {
      dispatch(clearSearchResults());
      setSearchTerm('');
    }
  }, [isOpen, dispatch]);

  // Debounced search function
  const debouncedSearch = debounce((search) => {
    if (search.trim()) {
      dispatch(searchUsers({ id: userId, token, search }));
    } else {
      dispatch(clearSearchResults());
    }
  }, 300);

  // Handle search input change
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    debouncedSearch(value);
  };

  const handleUserToggle = (user) => {
    // Check if user is already selected to prevent duplicates
    if (!formData.selectedUsers.has(user.id)) {
      setFormData(prev => {
        const newSelectedUsers = new Set(prev.selectedUsers);
        newSelectedUsers.add(user.id);
        return {
          ...prev,
          selectedUsers: newSelectedUsers
        };
      });
      setSelectedUserDetails(prev => [...prev, user]);
    }
  };

  const removeUser = (userId) => {
    setFormData(prev => {
      const newSelectedUsers = new Set(prev.selectedUsers);
      newSelectedUsers.delete(userId);
      return {
        ...prev,
        selectedUsers: newSelectedUsers
      };
    });
    setSelectedUserDetails(prev => prev.filter(user => user.id !== userId));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true)
      const updateData = {
        id: department.id,
        name: formData.name,
        users: Array.from(formData.selectedUsers)
      };

      const response = await api.put(
        `${api_url}/user/${userId}/department/${department.id}`,
        updateData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      fetchDepartmentDetails()
      onClose();
    } catch (error) {
      console.error('Error updating department:', error);
    } finally {
      setLoading(false)
    }
  };

  // Filter out already selected users from search results
  const filteredSearchResults = searchResults.filter(
    user => !formData.selectedUsers.has(user.id)
  );

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        className="fixed inset-0 bg-black bg-opacity-25"
        onClick={onClose}
      />

      <div className="bg-white rounded-lg w-full max-w-md mx-4 z-50 max-h-[90vh] overflow-auto">
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-base font-medium">Update Department</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 text-xl font-medium"
          >
            ×
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-4 space-y-4">
          {/* Department Name */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">Department Name</label>
            <input
              type="text"
              value={formData.name}
              onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
              className="w-full px-3 py-2 border border-gray-300 rounded-md text-sm focus:outline-none focus:ring-1 focus:ring-blue-500"
              placeholder="Enter department name"
            />
          </div>

          {/* Search Input */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">Search Users</label>
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder="Search users..."
              className="w-full px-3 py-2 border border-gray-300 rounded-md text-sm focus:outline-none focus:ring-1 focus:ring-blue-500"
            />
          </div>

          {/* Selected Users */}
          {selectedUserDetails.length > 0 && (
            <div className="space-y-2">
              <div className="flex justify-between items-center">
                <label className="block text-sm font-medium text-gray-700">Selected Users</label>
                <span className="text-xs text-gray-500">
                  Total: {selectedUserDetails.length}
                </span>
              </div>
              <div className="flex flex-wrap gap-2">
                {selectedUserDetails.map((user) => (
                  <div
                    key={user.id}
                    className="flex items-center gap-1 px-2 py-1 bg-blue-50 text-blue-700 rounded-md text-sm"
                  >
                    <span>{user.first_name} {user.last_name}</span>
                    <button
                      type="button"
                      onClick={() => removeUser(user.id)}
                      className="p-0.5 hover:bg-blue-100 rounded"
                    >
                      <X size={14} />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Search Results */}
          {(searchTerm || filteredSearchResults.length > 0) && (
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">Search Results</label>
              <div className="border border-gray-200 rounded-md max-h-48 overflow-y-auto">
                <div className="p-2">
                  {searchStatus === 'loading' ? (
                    <div className="flex justify-center items-center h-24">
                      <span className="text-gray-500">Loading users...</span>
                    </div>
                  ) : filteredSearchResults.length > 0 ? (
                    <div className="space-y-2">
                      {filteredSearchResults.map((user) => (
                        <div
                          key={user.id}
                          className="flex items-center space-x-3 p-2 hover:bg-gray-50 rounded-md cursor-pointer"
                        >
                          <input
                            type="checkbox"
                            checked={formData.selectedUsers.has(user.id)}
                            onChange={() => handleUserToggle(user)}
                            className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                          />
                          <label
                            className="text-sm font-medium text-gray-700 cursor-pointer"
                            onClick={() => handleUserToggle(user)}
                          >
                            {user.first_name} {user.last_name}
                          </label>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="flex justify-center items-center h-24 text-gray-500 text-sm">
                      {searchTerm ? 'No users found' : 'Start typing to search users'}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {/* Buttons */}
          <div className="flex justify-end gap-2 pt-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-1.5 text-sm border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-1.5 text-sm bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              {loading ?
                <ColorRing
                  visible={true}
                  height="20"
                  width="20"
                  ariaLabel="color-ring-loading"
                  wrapperStyle={{}}
                  wrapperClass="color-ring-wrapper"
                  colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                /> : `Update`}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateDepartmentModal;