import React, { useState } from 'react';
import { BsArrowLeft } from 'react-icons/bs';

const SubscriptionsPerAccount = () => {
    const [selectedSubscription, setSelectedSubscription] = useState(null);
    const [isTransitioning, setIsTransitioning] = useState(false);

    const subscriptions = [
        {
            id: 1,
            package: 'Pharmtrix Retail',
            billing: '$45/Quarterly',
            branches: '4',
            users: '400',
            next_billing: '20/09/2024',
            status: 'active'
        },
        {
            id: 2,
            package: 'Pharmtrix Wholesale',
            billing: '$15/Monthly',
            branches: '2',
            users: '200',
            next_billing: '20/09/2024',
            status: 'active'
        },
        {
            id: 3,
            package: 'Labtrix',
            billing: '$60/Semi-annually',
            branches: '6',
            users: '600',
            next_billing: '20/09/2024',
            status: 'active'
        },
        {
            id: 4,
            package: 'Billtrix',
            billing: '$25/Quarterly',
            branches: '4',
            users: '400',
            next_billing: '20/09/2024',
            status: 'active'
        },
    ];

    const handleSelectSubscription = (sub) => {
        setIsTransitioning(true);
        setTimeout(() => {
            setSelectedSubscription(sub);
            setIsTransitioning(false);
        }, 300);
    };

    const handleBackToList = () => {
        setIsTransitioning(true);
        setTimeout(() => {
            setSelectedSubscription(null);
            setIsTransitioning(false);
        }, 300);
    };

    const PackageList = () => (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {subscriptions.map((sub) => (
                <button
                    key={sub.id}
                    className="p-6 bg-white hover:bg-gray-50 border border-gray-200 rounded-xl text-left transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-xl focus:outline-none focus:ring-2 focus:ring-blue-500"
                    onClick={() => handleSelectSubscription(sub)}
                >
                    <h2 className="font-bold text-sm text-gray-800 mb-2">{sub.package}</h2>
                    <p className="text-xs text-gray-600">{sub.billing}</p>
                </button>
            ))}
        </div>
    );

    const PackageDetails = ({ subscription }) => (
        <div className="  px-5 ">
            <button 
                className="mb-2 flex items-center text-blue-600 hover:text-blue-800 transition-colors duration-200 focus:outline-none"
                onClick={handleBackToList}
            >
                <BsArrowLeft size={24} className="mr-2" />
                <span className="text-xs">Back to Packages</span>
            </button>
            <h2 className="text-xl font-bold mb-2 text-gray-800">{subscription.package}</h2>
            <div className="">
                {Object.entries(subscription).map(([key, value]) => (
                    key !== 'id' && key !== 'package' && (
                        <div key={key} className="flex justify-between items-center py-1 text-sm">
                            <span className="text-gray-600 capitalize">{key.replace('_', ' ')}:</span>
                            <span className="font-semibold text-gray-800">{value}</span>
                        </div>
                    )
                ))}
            </div>
        </div>
    );

    return (
        <div className="max-w-4xl mx-auto p-4 rounded-lg shadow-md bg-white w-full">
            <h1 className="text-sm font-bold text-center text-gray-800 mb-2">Your Subscriptions</h1>
            <div className={`transition-opacity duration-300 ease-in-out ${isTransitioning ? 'opacity-0' : 'opacity-100'}`}>
                {selectedSubscription ? (
                    <PackageDetails subscription={selectedSubscription} />
                ) : (
                    <PackageList />
                )}
            </div>
        </div>
    );
};

export default SubscriptionsPerAccount;