import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ColorRing } from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import api from '../utils/Api';
import { api_url } from '../config';
import { toast } from 'react-toastify';
import EditUserForm from './EditUserForm';
import ResetPasswordModal from './ResetPasswordModal';

// Utility function to get role label from value
const getRoleLabel = (roleValue) => {
  const roleMap = {
    subscriberViewer: 'View subscribers',
    subscriberWriter: 'Manage subscribers',
    backlogViewer: 'View Backlogs',
    backlogWriter: 'Manage Backlogs',
    taskViewer: 'View Tasks',
    taskWriter: 'Manage Tasks',
    qualityAssuaranceViewer: 'View Quality Assuarance',
    qualityAssuaranceWriter: 'Manage Quality Assuarance',
    archiveViewer: 'View Archives',
    archiveWriter: 'Manage Archives',
    departmentViewer: 'View Departments',
    departmentWriter: 'Manage Departments',
    userViewer: 'View Users',
    userWriter: 'Manage Users',
    executiveViewer: 'Executive View'
  };

  return roleMap[roleValue] || roleValue;
};

// Utility function to group roles by their category
const groupRolesByCategory = (roles) => {
  const groupedRoles = {
    'Subscriber Management': [],
    'Backlog Management': [],
    'Task Management': [],
    'QA Management': [],
    'Archive Management': [],
    'Departments Management': [],
    'User Management': [],
    'Other Permissions': []
  };

  roles.forEach(role => {
    if (role.includes('subscriber')) {
      groupedRoles['Subscriber Management'].push(role);
    } else if (role.includes('backlog')) {
      groupedRoles['Backlog Management'].push(role);
    } else if (role.includes('task')) {
      groupedRoles['Task Management'].push(role);
    } else if (role.includes('quality')) {
      groupedRoles['QA Management'].push(role);
    } else if (role.includes('archive')) {
      groupedRoles['Archive Management'].push(role);
    } else if (role.includes('department')) {
      groupedRoles['Departments Management'].push(role);
    } else if (role.includes('user')) {
      groupedRoles['User Management'].push(role);
    } else {
      groupedRoles['Other Permissions'].push(role);
    }
  });

  // Remove empty categories
  return Object.fromEntries(
    Object.entries(groupedRoles).filter(([_, roles]) => roles.length > 0)
  );
};

const UserDetails = () => {
  const { id: userId } = useParams();
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showEditForm, setShowEditForm] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { token, id } = useSelector((state) => state.auth);
  const { users } = useSelector((state) => state.users);
  const navigate = useNavigate();
  const closeModal = () => setIsModalOpen(false);

  const fetchUserDetails = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await api.get(`${api_url}/user/${id}/user-profile/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      setUser(response.data.data);
    } catch (err) {
      toast.error('Error fetching user details');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, [userId]);

  if (error) {
    return (
      <div className="flex items-center justify-center h-full text-red-500">
        Error: {error}
      </div>
    );
  }

  return (
    <div>
      <div className="flex items-center gap-4 mb-6">
        <button
          onClick={() => navigate('/dashboard/users')}
          className="text-gray-600 hover:text-gray-800"
        >
          ←
        </button>
        <h1 className="text-base font-semibold">User Details</h1>
      </div>
      <div className="flex h-full w-full">
        <div className="w-1/3 bg-gray-50 p-2 rounded-lg shadow-md overflow-auto max-h-[80vh] hidden sm:block">
          <h2 className="text-lg font-semibold mb-4">User List</h2>
          <ul>
            {users.map((user) => (
              <li
                key={user.id}
                onClick={() => navigate(`/dashboard/users/${user.id}`)}
                className={`flex items-center justify-between border-b text-sm p-2 rounded-lg cursor-pointer transition-colors
                  ${user.id === parseInt(userId) ? 'bg-gray-200' : 'bg-white hover:bg-gray-100'}`}
              >
                <span>{user.first_name} {user.last_name}</span>
              </li>
            ))}
          </ul>
        </div>
        {isLoading ? (
          <div className="h-[50vh] w-full flex justify-center items-center">
            <ColorRing
              visible={true}
              height="60"
              width="60"
              ariaLabel="color-ring-loading"
              wrapperStyle={{}}
              wrapperClass="color-ring-wrapper"
              colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
            />
          </div>
        ) : (
          <div className="p-6 mx-auto w-full">
            <div className="bg-white rounded-lg shadow-sm p-1">
              <div className="flex flex-col mb-3 bg-slate-200 p-4 m-2 rounded-md">
                <h2 className="text-lg font-medium">{user.first_name} {user.last_name}</h2>
                <p className="text-sm text-gray-600">Status: {user.status}</p>
              </div>
              <div className="grid sm:grid-cols-2 sm:gap-y-8">
                <div className="p-4">
                  <p className="text-sm text-gray-600 mb-2">Email</p>
                  <p className="text-sm text-gray-600">{user.email}</p>
                </div>
                <div className="p-4">
                  <p className="text-sm text-gray-600 mb-2">Created By</p>
                  <p className="text-sm text-gray-600">{user?.created_by}</p>
                </div>
                <div className="p-4">
                  <p className="text-sm text-gray-600 mb-2">Departments</p>
                  <ul className="space-y-1">
                    {user.departments.map((dept) => (
                      <li key={dept.id} className="text-sm">• {dept?.name}</li>
                    ))}
                  </ul>
                </div>
                <div className="p-4 ">
                  <p className="text-sm text-gray-600 mb-2">Roles</p>
                  {Object.entries(groupRolesByCategory(user.roles)).map(([category, roles]) => (
                    <div key={category} className="mb-3 grid grid-col-1 sm:grid-cols-2 ">
                      <h3 className="text-sm font-medium text-gray-700 mb-1">{category}</h3>
                      <ul className="space-y-1 ml-4">
                        {roles.map((role) => (
                          <li key={role} className="text-sm text-gray-600">
                            • {getRoleLabel(role)}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex justify-end m-3 gap-4">
                <button
                  onClick={() => setIsModalOpen(true)}
                  className="bg-gray-500 text-white text-xs sm:text-sm py-1 px-2 sm:px-4 rounded hover:bg-gray-600 transition-colors"
                >
                  Reset Password
                </button>
                <button
                  onClick={() => setShowEditForm(true)}
                  className="bg-blue-500 text-white text-xs sm:text-sm py-1 px-2 sm:px-4 rounded hover:bg-blue-600 transition-colors"
                >
                  Edit Profile
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className={`fixed top-0 right-0 h-full z-50 bg-white shadow-lg transform transition-transform duration-300 ${showEditForm ? 'translate-x-0' : 'translate-x-full'}`}>
        <EditUserForm
          isOpen={showEditForm}
          onClose={() => setShowEditForm(false)}
          fetchUser={fetchUserDetails}
          token={token}
          id={id}
          userData={user}
        />
      </div>

      <ResetPasswordModal
        isOpen={isModalOpen}
        onClose={closeModal}
        token={token}
        user={user}
      />
    </div>
  );
};

export default UserDetails;