// DepartmentsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../utils/Api';
import { api_url } from '../config';

// Async thunk to fetch departments
export const fetchDepartments = createAsyncThunk(
    'departments/fetchDepartments',
    async ({ id, token }, { rejectWithValue }) => {
        try {
            const response = await api.get(`${api_url}/user/${id}/department`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            return response.data.data;
        } catch (error) {
            console.error('Error fetching departments:', error);
            return rejectWithValue(error.response.data);
        }
    }
);

const departmentsSlice = createSlice({
    name: 'departments',
    initialState: {
        departments: [],
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDepartments.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchDepartments.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.departments = action.payload;
            })
            .addCase(fetchDepartments.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

export default departmentsSlice.reducer;
