import axios from 'axios';
import store from '../Store'; 
import { clearAuthData } from '../Redux/AuthSlice';
import { toast } from 'react-toastify';

const api = axios.create();

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.data.message === "unAuthenticated") {
      store.dispatch(clearAuthData());
      
      // window.location.href = '/';
      toast.error('Session expired. Please login again')    
    }else if(error.response && error.response.data.message === "subscription-expired"){
      store.dispatch(clearAuthData());
      
      // window.location.href = '/';
      toast.error('Your subscription is expired. Kindly update it to continue to use our tool') 
    }
    return Promise.reject(error);
  }
);

export default api;