import React, { useEffect, useState } from 'react';
import { fetchDepartments } from '../Redux/DepartmentSlice';
import { useDispatch, useSelector } from 'react-redux';
import api from '../utils/Api';
import { api_url } from '../config';

const UpdateBacklogForm = ({ isOpen, onClose, issue,fetchBacklogs }) => {
    const [formData, setFormData] = useState({
        description: '',
        department_id: '',
        assignee_id: '',
        app: '',
        priority: '',
        status: ''
    });
    const [departmentUsers, setDepartmentUsers] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(false);
    
    const { id, token } = useSelector((state) => state.auth);
    const { departments } = useSelector((state) => state.departments);
    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(fetchDepartments({ id, token }));
    }, [dispatch, id, token]);

    useEffect(() => {
        if (issue) {
            setFormData({
                description: issue.description || '',
                department_id: issue.department?.id || '',
                assignee_id: issue.assignee?.id || '', // Updated to handle new assignee structure
                app: issue.app || '',
                priority: issue.priority || '',
                status: issue.status || ''
            });

            if (issue.department && departments.length > 0) {
                const initialDept = departments.find(dept => dept.id === issue.department.id);
                setDepartmentUsers(initialDept?.users || []);
            }
        }
    }, [issue, departments]);

    useEffect(() => {
        if (formData.department_id && departments) {
            const selectedDept = departments.find(dept => dept.id === parseInt(formData.department_id));
            if (selectedDept) {
                setDepartmentUsers(selectedDept.users || []);
                setFormData(prev => ({ ...prev, assignee_id: '' }));
            }
        }
    }, [formData.department_id, departments]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setLoading(true);
        
        try {
            const response = await api.put(
                `${api_url}/user/${id}/backlogs/${issue.id}`,
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                }
            );

            if (response.status === 200) {
                onClose();
                fetchBacklogs()
            } else {
                throw new Error('Failed to update issue');
            }
        } catch (err) {
            setError('Failed to update issue. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg w-full max-w-xl mx-3 sm:mx-0">
                <h2 className="text-xl border-b p-4">Update Issue</h2>

                {error && (
                    <div className="mx-6 mt-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded">
                        {error}
                    </div>
                )}

                <form onSubmit={handleSubmit} className="p-6">
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700 mb-2">Issue</label>
                        <textarea
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            className="border border-gray-300 rounded-md p-2 w-full"
                            rows={3}
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700 mb-2">Product</label>
                        <select
                            name="app"
                            value={formData.app}
                            onChange={handleChange}
                            className="border border-gray-300 rounded-md p-2 w-full"
                            required
                        >
                            <option value="" disabled>Select</option>
                            <option value="pharmtrix-wholesale">Pharmtrix Wholesale</option>
                            <option value="pharmtrix-retail">Pharmtrix Retail</option>
                            <option value="centrix">Centrix</option>
                            <option value="billtrix">Billtrix</option>
                            <option value="labtrix">Labtrix</option>
                        </select>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-3">
                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700 mb-2">Department</label>
                            <select
                                name="department_id"
                                value={formData.department_id}
                                onChange={handleChange}
                                className="border border-gray-300 rounded-md p-2 w-full"
                                required
                            >
                                <option value="" disabled>Select</option>
                                {departments?.map((dept) => (
                                    <option key={dept.id} value={dept.id}>
                                        {dept.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700 mb-2">Assignee</label>
                            <select
                                name="assignee_id"
                                value={formData.assignee_id}
                                onChange={handleChange}
                                className="border border-gray-300 rounded-md p-2 w-full"
                            >
                                <option value="" disabled>Select</option>
                                {departmentUsers.map((user) => (
                                    <option 
                                        key={user.id} 
                                        value={user.id}
                                        selected={user.id === issue?.assignee?.id} // Added selected attribute
                                    >
                                        {`${user.first_name} ${user.last_name}`}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700 mb-2">Priority</label>
                            <select
                                name="priority"
                                value={formData.priority}
                                onChange={handleChange}
                                className="border border-gray-300 rounded-md p-2 w-full"
                                required
                            >
                                <option value="" disabled>Select</option>
                                <option value="low">Low</option>
                                <option value="medium">Medium</option>
                                <option value="high">High</option>
                            </select>
                        </div>
                    </div>
                    <div className="flex justify-end gap-3 mt-6">
                        <button
                            type="button"
                            onClick={onClose}
                            className="text-gray-500 border border-gray-300 px-4 py-2 rounded text-xs"
                            disabled={isLoading}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="bg-blue-600 text-white px-4 py-2 rounded text-xs disabled:bg-blue-400"
                            disabled={isLoading}
                        >
                            {isLoading ? 'Updating...' : 'Update Issue'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default UpdateBacklogForm;