import React, { useState } from 'react';

const UpdateSubscriberProfile = ({isOpen,onClose}) => {

    const [email, setEmail] = useState('info@LetapPharmaceuticals.com');
    const [companyName, setCompanyName] = useState('Letap Pharmaceuticals Ltd');
    const [companyAddress, setCompanyAddress] = useState('Plot No. 41, South Industrial Area');
    const [adminFullName, setAdminFullName] = useState('Charles Randy Ampah');
    const [businessPhoneNumber, setBusinessPhoneNumber] = useState('050 226 4295');
    const [password, setPassword] = useState('');

    if (!isOpen) return null;

    const handleUpdateProfile = () => {
        // Handle profile update logic here
        console.log('Profile updated', { email, companyName, companyAddress, adminFullName, businessPhoneNumber, password });
        // Optionally close the modal after update
        onClose();
    };


    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        <div className="bg-white rounded-lg  w-full max-w-2xl mx-3 sm:mx-0 ">
            <h2 className="text-xl border-b p-4">Update Account Profile</h2>
         
           <form className='grid grid-cols-1 sm:grid-cols-2 gap-3 p-6'>
           <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">Email</label>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="border border-gray-300 rounded-md p-2 w-full"
                />
            </div>
            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">Company Name</label>
                <input
                    type="text"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    className="border border-gray-300 rounded-md p-2 w-full"
                />
            </div>
            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">Company Address</label>
                <input
                    type="text"
                    value={companyAddress}
                    onChange={(e) => setCompanyAddress(e.target.value)}
                    className="border border-gray-300 rounded-md p-2 w-full"
                />
            </div>
            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">Admin Full Name</label>
                <input
                    type="text"
                    value={adminFullName}
                    onChange={(e) => setAdminFullName(e.target.value)}
                    className="border border-gray-300 rounded-md p-2 w-full"
                />
            </div>
            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">Business Phone Number</label>
                <input
                    type="text"
                    value={businessPhoneNumber}
                    onChange={(e) => setBusinessPhoneNumber(e.target.value)}
                    className="border border-gray-300 rounded-md p-2 w-full"
                />
            </div>
           
           </form>
            <div className="flex justify-end gap-3 p-4">
                <button onClick={onClose} className="text-gray-500 border border-gray-300 px-2 py-1 rounded text-xs">
                    Cancel
                </button>
                <button onClick={handleUpdateProfile} className="bg-blue-600 text-white px-2 py-1 rounded text-xs">
                    Update Account Profile
                </button>
            </div>
        </div>
    </div>
    );
}

export default UpdateSubscriberProfile;
