import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../utils/Api';
import { api_url } from '../config';
import { toast } from 'react-toastify';

// Async thunk for fetching users with search
export const searchUsers = createAsyncThunk(
    'users/searchUsers',
    async ({ id, token, search = '' }, { rejectWithValue }) => {
        try {
            const response = await api.get(`${api_url}/user/${id}/user-profile`, {
                params: {
                    search,
                    limit: 10 
                },
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            return response.data;
        } catch (error) {
            toast.error('Error in searching users');
            return rejectWithValue(error.response?.data || 'Error searching users');
        }
    }
);

// Original fetchUsers thunk remains the same
export const fetchUsers = createAsyncThunk(
    'users/fetchUsers',
    async ({ id, token, page = 1, limit = 15, status = undefined }, { rejectWithValue }) => {
        try {
            const response = await api.get(`${api_url}/user/${id}/user-profile`, {
                params: {
                    page,
                    limit,
                    status: status !== 'all' ? status : undefined
                },
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            return response.data;
        } catch (error) {
            toast.error('Error in fetching Users');
            return rejectWithValue(error.response?.data || 'Error fetching users');
        }
    }
);

const usersSlice = createSlice({
    name: 'users',
    initialState: {
        users: [],
        searchResults: [], // New state for search results
        pagination: {
            currentPage: 1,
            lastPage: 1,
            total: 0,
            perPage: 15
        },
        status: 'idle',
        searchStatus: 'idle', // New status for search
        error: null,
        selectedStatus: 'all'
    },
    reducers: {
        setSelectedStatus: (state, action) => {
            state.selectedStatus = action.payload;
            state.pagination.currentPage = 1;
        },
        setCurrentPage: (state, action) => {
            state.pagination.currentPage = action.payload;
        },
        clearSearchResults: (state) => {
            state.searchResults = [];
            state.searchStatus = 'idle';
        }
    },
    extraReducers: (builder) => {
        builder
            // Original fetchUsers cases
            .addCase(fetchUsers.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUsers.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.users = action.payload.data || [];
                state.pagination = {
                    currentPage: action.payload.meta.current_page,
                    lastPage: action.payload.meta.last_page,
                    total: action.payload.meta.total,
                    perPage: action.payload.meta.per_page
                };
            })
            .addCase(fetchUsers.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            // New searchUsers cases
            .addCase(searchUsers.pending, (state) => {
                state.searchStatus = 'loading';
            })
            .addCase(searchUsers.fulfilled, (state, action) => {
                state.searchStatus = 'succeeded';
                state.searchResults = action.payload.data || [];
            })
            .addCase(searchUsers.rejected, (state, action) => {
                state.searchStatus = 'failed';
                state.error = action.payload;
            });
    }
});

export const { setSelectedStatus, setCurrentPage, clearSearchResults } = usersSlice.actions;
export default usersSlice.reducer;